module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-md-menus/gatsby-browser.js'),
      options: {"plugins":[],"config":{"editInBitbucketButton":{"isVisible":"true"},"gatsby":{"pathPrefix":"/","siteUrl":"https://mimik.com","gaTrackingId":"G-JFFLQ8P9EL","getTagManagerTrackingId":"GTM-T2WXR64","linkedInPartnerId":"3195972","trailingSlash":false},"header":{"logo":"https://developer.mimik.com/wp-content/uploads/2021/10/498ec051afc558f8b4f25be6eed00c71.jpg","logoLink":"https://devdocs.mimik.com","title":"","githubUrl":"https://github.com/mimikgit","helpUrl":"","tweetText":"","social":"<li><div></li>\n    <li><div></li>\n    <li><div></li>","links":[{"text":"","link":""}],"search":{"enabled":true,"indexName":"mimik","algoliaAppId":"A64JPKR8N9","algoliaSearchKey":"bb9cef06bb9549dd528a75b95d7a22f6","algoliaAdminKey":"9a836fe039d7fb40ffeae459f3a31b39"}},"sidebar":{"forcedNavOrder":["/introduction","/key-concepts","/tutorials","/api","/faqs","/troubleshooting","/misc"],"collapsedNav":["/introduction","/key-concepts","/tutorials","/api","/faqs","/misc"],"links":[{"text":"Developer Portal","link":"https://developer.mimik.com"},{"text":"Developer Console","link":"https://console.mimik.com"},{"text":"mimik website","link":"https://mimik.com"}],"frontline":false,"ignoreIndex":true,"title":"mimik Developer Documentation"},"siteMetadata":{"title":"mimik Developer Portal | mimik","description":"Documentation built with mdx. Powering mimik.com ","ogImage":null,"docsLocation":"https://bitbucket.org/mimiktech/dev-portal-content/src/develop/content/"},"pwa":{"enabled":false,"manifest":{"name":"mimik Developer Portal","short_name":"mimikDeveloper","start_url":"/","background_color":"#6b37bf","theme_color":"#6b37bf","display":"standalone","crossOrigin":"use-credentials","icons":[{"src":"src/pwa-512.png","sizes":"512x512","type":"image/png"}]}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/templates/docs.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"3070981","respectDNT":true,"productionOnly":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1035}},{"resolve":"gatsby-remark-copy-linked-files"}],"extensions":[".mdx",".md"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-JFFLQ8P9EL","head":true,"anonymize":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T2WXR64","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"defaultDataLayer":{"type":"function","value":"function () {\n        return {\n          pageType: window.pageType,\n        }\n      }"},"includeInDevelopment":false,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
